import '../node_modules/alpinejs/dist/cdn';
import * as Rellax from '../node_modules/rellax';
import '../node_modules/bootstrap';
import Swiper from 'swiper';

import SwiperCore, {Pagination, Autoplay, EffectFade, Navigation, Thumbs, Controller} from 'swiper/core';

SwiperCore.use([Pagination, Autoplay, EffectFade, Navigation, Thumbs, Controller]);

// @ts-ignore
window.Swiper = Swiper;
new Rellax('.rellax');
